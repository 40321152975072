import { HStack, MenuItemOption, MenuItemOptionProps, Text } from '@chakra-ui/react';
import { MdChevronRight } from 'react-icons/md';
import OptionCountLabel from './OptionCountLabel';

type DropdownMenuOptionProps = MenuItemOptionProps & {
  label: string;
  values: string[] | number[];
};

const DropdownMenuOption = (props: DropdownMenuOptionProps) => {
  const { label, values, ...restProps } = props;

  return (
    <MenuItemOption {...restProps}>
      <HStack w='full' justifyContent='space-between'>
        <Text>{label}</Text>
        <OptionCountLabel optionCount={values.length} />
        <MdChevronRight />
      </HStack>
    </MenuItemOption>
  );
};

export default DropdownMenuOption;
