import { MenuItem, MenuItemProps } from '@chakra-ui/react';

const CustomMenuItem = (props: MenuItemProps) => {
  const { children, ...rest } = props;
  return (
    <MenuItem
      as='div'
      display='flex'
      alignItems='stretch'
      bg='transparent'
      _hover={{
        bg: 'transparent',
      }}
      p={0}
      m={0}
      {...rest}
    >
      {children}
    </MenuItem>
  );
};

export default CustomMenuItem;
