import { OptionValue } from '../types';
import { CustomFieldOptionType, OrganizedFilterItemsType } from './CommonOrganizedFilterWrapper';
import CustomMenuItem from './CustomMenuItem';
import DropdownMenu from './DropdownMenu';
import MultipleLayerCheckbox from './MultipleLayerCheckbox';

type CustomFieldOptionsFilterProps = {
  organizedFilterItemsOpenValue: OrganizedFilterItemsType;
  customFieldOptions: CustomFieldOptionType[];
  customFieldOptionsMap: Map<number, number[]>;
  defaultOrganizedFilterItemsOpenValue?: OrganizedFilterItemsType;
  setOrganizedFilterItemsOpenValue: (value: OrganizedFilterItemsType) => void;
  resetOrganizedFilterItemsOpenValue: () => void;
  filterCustomFieldOptionIds: (customFieldId: number, optionIds?: OptionValue[]) => void;
};

const dropdownContainerHeight = 430;

const CustomFieldOptionsFilter = (props: CustomFieldOptionsFilterProps) => {
  const {
    organizedFilterItemsOpenValue,
    customFieldOptions,
    customFieldOptionsMap,
    defaultOrganizedFilterItemsOpenValue = {},
    setOrganizedFilterItemsOpenValue,
    resetOrganizedFilterItemsOpenValue,
    filterCustomFieldOptionIds,
  } = props;

  return customFieldOptions.map(({ label, customFieldId, selectOptions }) => {
    const values = customFieldOptionsMap.get(customFieldId) || [];
    // NOTE: 40 is the height of each option, 80 is the height of search bar and paddings
    const height = 40 * selectOptions.length + 80;
    const containerHeight = height > dropdownContainerHeight ? dropdownContainerHeight : height;
    return (
      <CustomMenuItem key={customFieldId}>
        <DropdownMenu
          label={label}
          values={values}
          containerSx={{ minH: containerHeight }}
          isOpen={!!organizedFilterItemsOpenValue[customFieldId]}
          onToggle={() =>
            setOrganizedFilterItemsOpenValue({
              ...defaultOrganizedFilterItemsOpenValue,
              [customFieldId]: !organizedFilterItemsOpenValue[customFieldId],
            })
          }
          onClose={resetOrganizedFilterItemsOpenValue}
        >
          <MultipleLayerCheckbox
            label={label}
            isDefaultOpen
            options={selectOptions}
            onChange={(optionIds?: OptionValue[]) =>
              filterCustomFieldOptionIds(customFieldId, optionIds)
            }
            values={values}
            onCloseFilter={resetOrganizedFilterItemsOpenValue}
            isInMenu={true}
          />
        </DropdownMenu>
      </CustomMenuItem>
    );
  });
};

export default CustomFieldOptionsFilter;
